import { Trans, useTranslation } from "react-i18next"
import { type FormEvent, useState } from "react"
import captureException from "@utils/captureException"

import TextInput from "@components/TextInput"
import Button from "@components/Button"
import registerForNewsletter from "@utils/registerForNewsletter"
import Container from "@components/Container"
import Link from "@components/Link"
import useToast from "@hooks/useToast"
import Checkbox from "@components/Checkbox"
import type { LayoutDef } from "@types"
import Illustration from "@components/Illustration"

interface FooterNewsletterProps {
  layout: LayoutDef
}

const FooterNewsletter = (props: FooterNewsletterProps): JSX.Element => {
  const { t } = useTranslation<string>("common")
  const { showToast } = useToast()
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)

  const [email, setEmail] = useState<string>("")
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setIsButtonLoading(true)
    if (!termsAccepted) {
      setSubmittedOnce(true)
      setIsButtonLoading(false)
      return
    }
    try {
      await registerForNewsletter({
        email: email,
        lastName: "",
        firstName: "",
      })
      setIsButtonLoading(false)
      setSuccess(true)
    } catch (error: any) {
      const parsedError = JSON.parse(error)

      if (parsedError?.error === "Member already exists") {
        setIsButtonLoading(false)
        showToast(t("newsletter.newsletterUserExistsToastMessage"))
      } else if (parsedError?.error === "Something went wrong") {
        setIsButtonLoading(false)
        showToast(t("newsletter.newsletterUserGeneralError"))
      } else {
        captureException(error)
        setIsButtonLoading(false)
        showToast(t("newsletter.newsletterUserGeneralError"))
      }
    }
  }

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted)
  }

  return (
    <div className="border-t border-neutral-200 py-64 md:py-80" id="newsletter-signup">
      <Container>
        <div className="grid grid-cols-24 md:gap-x-16">
          <div className="col-span-24 md:col-span-12">
            <div className="flex items-center mb-40 md:mb-0">
              <div className="mr-16 md:mr-48 pl-0 md:pl-20">
                <div className="w-80 h-80 md:w-128 md:h-128 text-spinach">
                  <Illustration illustration={props.layout.newsletter_illustration.value} />
                </div>
              </div>
              <div>
                <p className="text-24 md:text-40 leading-[120%] font-serif text-spinach lg:pr-80">
                  {props.layout.newsletter_headline}
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-24 md:col-span-12 md:flex md:items-center">
            {!success && (
              <form onSubmit={handleSubmit} data-cy="newsletter-dialog" className="w-full">
                <div className="md:flex w-full">
                  <TextInput
                    required
                    label={t("newsletter.emailAddress")}
                    value={email}
                    onChange={setEmail}
                    type="email"
                    version="NEWSLETTER"
                    stretch
                    data-cy="newsletter-email"
                  />

                  <div className="mt-16 md:mt-0 md:ml-8">
                    <Button
                      version="SPINACH"
                      size="NEWSLETTER"
                      is_block
                      type="submit"
                      is_loading={isButtonLoading}
                      data-cy="newsletter-submit"
                    >
                      {t("newsletter.signup")}
                    </Button>
                  </div>
                </div>
                <div className="mt-16">
                  <Checkbox
                    id="newsletter-terms"
                    data-cy="newsletter-terms"
                    super_small_text
                    label={
                      <Trans
                        i18nKey="common:newsletter.acceptTerms"
                        components={[
                          <Link
                            class_name="underline hover:no-underline"
                            href="/datenschutzerklaerung"
                            new_tab
                            key={0}
                          />,
                        ]}
                      />
                    }
                    checked={termsAccepted}
                    has_error={!termsAccepted && submittedOnce}
                    text_gray
                    onChange={handleCheckboxChange}
                  />
                </div>
              </form>
            )}
            {success && <p className="text-neutral-300 md:max-w-[42rem]">{t("newsletter.newsletterSuccessMessage")}</p>}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FooterNewsletter
