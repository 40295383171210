import { ReactComponent as Avocado } from "src/assets/illustrations/avocado.svg"
import { ReactComponent as Baby } from "src/assets/illustrations/baby.svg"
import { ReactComponent as Beard } from "src/assets/illustrations/bart.svg"
import { ReactComponent as Blender } from "src/assets/illustrations/mixer.svg"
import { ReactComponent as Burger } from "src/assets/illustrations/burger.svg"
import { ReactComponent as Chef } from "src/assets/illustrations/koch.svg"
import { ReactComponent as ChefBreak } from "src/assets/illustrations/koch-pause.svg"
import { ReactComponent as ChefTuttoBene } from "src/assets/illustrations/koch-tutto-bene.svg"
import { ReactComponent as Chili } from "src/assets/illustrations/chili.svg"
import { ReactComponent as Dough } from "src/assets/illustrations/teig.svg"
import { ReactComponent as Egg } from "src/assets/illustrations/ei.svg"
import { ReactComponent as Fire } from "src/assets/illustrations/feuer.svg"
import { ReactComponent as Glasses } from "src/assets/illustrations/brille-beschlagen.svg"
import { ReactComponent as HappyPlates } from "src/assets/illustrations/happy-plates.svg"
import { ReactComponent as Knife } from "src/assets/illustrations/messer.svg"
import { ReactComponent as Lemonade } from "src/assets/illustrations/limonade.svg"
import { ReactComponent as LemonTree } from "src/assets/illustrations/zitronenbaum.svg"
import { ReactComponent as Melon } from "src/assets/illustrations/melone.svg"
import { ReactComponent as MetalHead } from "src/assets/illustrations/metaler.svg"
import { ReactComponent as MetalHeadFruit } from "src/assets/illustrations/metaler-obst.svg"
import { ReactComponent as Onion } from "src/assets/illustrations/zwiebel.svg"
import { ReactComponent as Pancake } from "src/assets/illustrations/pancake.svg"
import { ReactComponent as Pepper } from "src/assets/illustrations/pfeffer.svg"
import { ReactComponent as Pumpkin } from "src/assets/illustrations/kuerbis.svg"
import { ReactComponent as Salad } from "src/assets/illustrations/salat.svg"
import { ReactComponent as Schnitzel } from "src/assets/illustrations/schnitzel.svg"
import { ReactComponent as Smoke } from "src/assets/illustrations/rauch.svg"
import { ReactComponent as Vampire } from "src/assets/illustrations/vampir.svg"
import { ReactComponent as WomanKnifeLemon } from "src/assets/illustrations/frau-messer-zitrone.svg"

export type IllustrationType =
  | "Avocado"
  | "Baby"
  | "Beard"
  | "Blender"
  | "Burger"
  | "Chef"
  | "ChefBreak"
  | "ChefTuttoBene"
  | "Chili"
  | "Dough"
  | "Egg"
  | "Fire"
  | "Glasses"
  | "HappyPlates"
  | "Knife"
  | "Lemonade"
  | "LemonTree"
  | "Melon"
  | "MetalHead"
  | "MetalHeadFruit"
  | "Onion"
  | "Pancake"
  | "Pepper"
  | "Pumpkin"
  | "Salad"
  | "Schnitzel"
  | "Smoke"
  | "Vampire"
  | "WomanKnifeLemon"

export interface IllustrationProps {
  illustration: IllustrationType
}

const Illustration = (props: IllustrationProps): JSX.Element => {
  const { illustration } = props
  switch (illustration) {
    case "Avocado":
      return <Avocado />
    case "Baby":
      return <Baby />
    case "Beard":
      return <Beard />
    case "Blender":
      return <Blender />
    case "Burger":
      return <Burger />
    case "Chef":
      return <Chef />
    case "ChefBreak":
      return <ChefBreak />
    case "ChefTuttoBene":
      return <ChefTuttoBene />
    case "Chili":
      return <Chili />
    case "Dough":
      return <Dough />
    case "Egg":
      return <Egg />
    case "Fire":
      return <Fire />
    case "Glasses":
      return <Glasses />
    case "HappyPlates":
      return <HappyPlates />
    case "Knife":
      return <Knife />
    case "Lemonade":
      return <Lemonade />
    case "LemonTree":
      return <LemonTree />
    case "Melon":
      return <Melon />
    case "MetalHead":
      return <MetalHead />
    case "MetalHeadFruit":
      return <MetalHeadFruit />
    case "Onion":
      return <Onion />
    case "Pancake":
      return <Pancake />
    case "Pepper":
      return <Pepper />
    case "Pumpkin":
      return <Pumpkin />
    case "Salad":
      return <Salad />
    case "Schnitzel":
      return <Schnitzel />
    case "Smoke":
      return <Smoke />
    case "Vampire":
      return <Vampire />
    case "WomanKnifeLemon":
      return <WomanKnifeLemon />
    default:
      return <></>
  }
}

export default Illustration
