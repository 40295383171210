import { useTranslation } from "react-i18next"

import type { LayoutDef, StatamicFooterNavItemDef } from "@types"
import Image from "@components/Image"
import Container from "@components/Container"
import Link from "@components/Link"
import FooterNewsletter from "@components/Footer/FooterNewsletter"

import { ReactComponent as Sitemap } from "@icons/sitemap.svg"
import { ReactComponent as ChevronRightFilled } from "@icons/chevron-right-filled.svg"

interface FooterProps {
  layout: LayoutDef
  navigation: Array<StatamicFooterNavItemDef>
}

const Footer = (props: FooterProps): JSX.Element => {
  const { layout, navigation } = props
  const { t } = useTranslation<string>("common")

  const handleScrollToTop = () => {
    const top = document.querySelector("#top")

    if (!top) return

    top.scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <>
      <FooterNewsletter layout={layout} />
      <footer>
        <div className="bg-buttermilk-100 pb-96 md:pb-24" data-id="footer-impressum-wrapper">
          <Container>
            <div className="relative grid grid-cols-24 pt-64 md:gap-x-16 md:border-b md:border-neutral-200 md:pb-88 md:pt-80">
              {navigation.map((navigationColumn, index) => (
                <div
                  className="col-span-12 mb-40 md:col-span-5 md:mb-0 lg:col-span-4"
                  key={`${navigationColumn.page.title}-${index}`}
                >
                  <p className="pb-8 text-18 font-semibold md:pb-16">{navigationColumn.page.title}</p>
                  <ul>
                    {(navigationColumn?.children ?? []).map((navigationItem, i) => (
                      <li key={`${navigationItem.page.title}-${i}`}>
                        <Link
                          href={navigationItem.page.url}
                          class_name="block pb-8 text-14 font-medium leading-[120%] text-neutral-500"
                        >
                          {navigationItem.page.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div className="col-span-12 mb-40 md:col-span-6 md:col-start-16 md:mb-0 lg:col-span-7 lg:col-start-17">
                <p className="pb-8 text-18 font-semibold md:pb-28">{layout.footer_partner_headline}</p>
                <ul className="flex flex-wrap items-center">
                  {(layout.footer_partner ?? [])
                    .filter((partner) => partner && partner.image)
                    .map((partner, i) => (
                      <li key={i} className="flex relative w-80 h-48 mb-8 mr-20">
                        <div className="flex relative h-fit m-auto">
                          <Image src={partner.image.permalink} alt={partner.image.alt} height={48} width={80} contain />
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <button
                onClick={handleScrollToTop}
                className="absolute bottom-64 right-0 hidden w-40 -rotate-90 transform md:bottom-auto md:top-84 md:block"
              >
                <div className="absolute left-0 top-0 z-0 h-full w-full rounded-full bg-black transition-all duration-150 ease-out hover:scale-105"></div>
                <ChevronRightFilled className="pointer-events-none relative z-10 h-auto w-full" />
                <span className="sr-only">{t("footer.backToTop")}</span>
              </button>
            </div>

            {/* <CountryPicker className="mb-40 md:hidden" showLabel /> */}

            <div className="flex justify-between pt-24">
              <div data-id="copyright-info">
                <p className="text-12">© Happy Plates {new Date().getFullYear()}</p>
              </div>
              <div className="flex">
                <Link href="/sitemap/recipes/1" class_name="flex items-center">
                  <Sitemap className="mr-4 h-16 w-16 text-black" />
                  <span className="text-12 font-bold">Sitemap</span>
                </Link>
                {/* <CountryPicker className="hidden ml-24 md:block" showLabel /> */}
              </div>
            </div>
          </Container>
        </div>
      </footer>
    </>
  )
}

export default Footer
