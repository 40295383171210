import apiRequest from "@utils/apiRequest"

interface RegisterArg {
  firstName: string
  lastName: string
  email: string
}

export default async function registerForNewsletter(arg: RegisterArg): Promise<Response> {
  return apiRequest("/v1/lists/newsletter", {
    method: "POST",
    body: arg,
  })
}
